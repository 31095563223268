import React from "react";
import { getUser } from "../../utils/api";
import { useState, useEffect, useCallback } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { URL } from "../../utils/constants";
import { LinkComponent } from '../'
import styles from './styles.module.css'

const HermodoorsCard = ({ loadItem, updateOrders }) => {
  const { name } = useParams()
  const [station, setStation] = useState({ details: [], });
  const [link, setLink] = useState("");
  const { url } = useRouteMatch()
  const user = getUser
  const [admin, setAdmin] = useState(false)
  const staf_token = localStorage.getItem('auth_token') ? user().then((data) => { setAdmin(data.is_staff) }) : null
  const token = localStorage.getItem('auth_token')
  console.log(token)

  // Возможно это байт, так что потом удали

  useEffect(() => {
    fetch(`${URL}/api/hermodoors/${name}/`,
      {
        method: 'GET',
        headers: {
          'authorization': token ? `Token ${token}` : null
        }
      }
    )
      .then(response => response.json())
      .then(data => {
        setStation({ details: data });
      })
      .catch(error => console.error('Error:', error));
  }, []);

  const copyTextToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Текст успешно скопирован в буфер обмена!');
    } catch (err) {
      console.error('Ошибка:', err);
    }
  };
  
  // const archive_link = () => { for (const link in station.details.archive) 
    // {return <ul onClick={() => copyTextToClipboard(station.details.documentation)} style={{ color: "black" }}><a>{link}</a></ul>}
  // };

  const arr = station.details.archive

  const st = () => {return arr?.map(element => 
    
    (<ul onClick={() => copyTextToClipboard(element['archive_link'])} style={{ color: "black" }}><a>{element['representation_name']}</a></ul>)
  )}

  return (
    <div className={styles["card"]}>
      <ul style={{ color: "black" }}>{station.details.name}</ul>
      <ul style={{ color: "black" }}>{station.details.line}</ul>
      <ul style={{ color: "black" }}>{station.details.lat} {station.details.long}</ul>
      {/* <ul style={{ color: "black" }}><a>{station.details.archive?station.details.archive[0]['archive_link']:null}</a></ul> */}
      {/* {archive_link()} */}
      {st()}
      {/* {admin ? <ul><a href={`${url}/edit`} style={{ color: "black" }}>Редактировать содержимое станции</a></ul> : null} */}
    </div>
  )
}

export default HermodoorsCard
