import styles from './style.module.css';
import { URL } from "../../utils/constants";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Header = ({ loggedIn, onSignOut }) => {
  const token = localStorage.getItem('auth_token');
  const [user, setUser] = useState(null);

  useEffect(() => {
    fetch(`${URL}/api/users/me/`, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'authorization': token ? `Token ${token}` : null,
      },
    })
      .then(response => response.json())
      .then(data => {
        setUser(data);
      })
      .catch(error => console.error('Error:', error));
  }, []);

  return (
    <header className={styles.header}>
      <div className={styles.user}>
        {user?.username ? `Привет, ${user.username}` : "Гость"}
        <span className={styles.separator}></span>
      </div>
      <nav className={styles.nav}>
        <Link to="/" className={styles.link}>Главная</Link>
        {user ? (
          <button className={styles.button} onClick={onSignOut}>
            <span>Выйти</span>
          </button>
        ) : (
          <Link to="/signin" className={styles.button}>
            <span>Войти</span>
          </Link>
        )}
      </nav>
    </header>
  );
};

export default Header;
