import React from "react";
import { useHistory, useParams } from "react-router-dom";

import { updateCard } from "../../utils/api";
import { colorsList, getBase64, colorsNames } from "../../utils/constants";

import returnIcon from "../../images/left.svg";
import addImgIcon from "../../images/image.svg";
import removeIcon from "../../images/trash.svg";

import { ButtonSecondary } from "../ui/button-secondary/button-secondary";
import { Input } from "../ui/input/input";
import { ButtonForm } from "../ui/button-form/button-form";


import styles from "./edit-card-page.module.css";

export const EditCardPage = ({ data, setData, extraClass = "" }) => {
  const [currentColor, setCurrentColor] = React.useState("#FFFFFF");
  const [card, setCard] = React.useState({});
  const [achievements, setAchievements] = React.useState("");
  const [currentFileName, setCurrentFileName] = React.useState("");
  const [errorName, setErrorName] = React.useState("");
  const [errorAge, setErrorAge] = React.useState("");
  const { name } = useParams()

  const history = useHistory();

  console.log(data)

  const onChangeInput = (e) => {
    setCard({
      ...card,
      [e.target.name]: e.target.value,
    });
    e.target.name === "image" && setCurrentFileName(e.target.value);
  };

  const handleReturn = () => {
    history.goBack();
  };

  const handleRemoveImg = () => {
    setCard({ ...card, image: null });
  };

  const handleResponse = (res) => {
    if (typeof res.name === "object") {
      setErrorName("Поле с именем является обязательным");
    }
  };

  const handleSubmit = () => {
    errorAge && setErrorAge("");
    errorName && setErrorName("");

    const totalCard = {};
    const photo = document.querySelector('input[type="file"]').files[0];
    if (data.name !== card.name) {
      totalCard["name"] = card.name;
    }
    if (data.line !== card.line) {
      totalCard["line"] = card.line;
    }
    if (data.documentation !== card.documentation) {
      totalCard["documentation"] = card.documentation;
    }
    if (data.image !== card.image && card.image === null) {
      totalCard["image"] = card.image;
    }

    

    if (photo) {
      getBase64(photo).then((data) => {
        totalCard["image"] = data;
        console.log(card.name)
        console.log(card.id)
        updateCard(totalCard, name)
          .then((res) => {
            if (res && res.id) {
              history.replace({ pathname: `/` });
            }
          })
          .catch(handleResponse);
      });
    } else {
      updateCard(totalCard, name)
        .then((res) => {
          console.log(card.documentation)
          console.log(card)
          if (res && res.id) {
            history.replace({ pathname: `/` });
          }
        })
        .catch(handleResponse);
    }
  };

  return (
    <div className={`${styles.content} ${extraClass}`}>
      <h2 className="text text_type_h2 text_color_primary mt-25 mb-9">
        Редактировать станцию
      </h2>
      <ButtonSecondary
        extraClass={styles.return_btn_mobile}
        icon={returnIcon}
        onClick={handleReturn}
      />
      <div style={{backgroundColor: "navy"}} className={styles.container}>
        {!currentFileName && card.image ? (
          <div className={styles.img_box}>
            <img
              className={styles.current_img}
              src={card.image}
              alt="Фото станции."
            />
            <ButtonSecondary
              extraClass={styles.remove_btn}
              icon={removeIcon}
              onClick={handleRemoveImg}
            />
          </div>
        ) : (
          <label htmlFor="image" className={styles.img_box}>
            <img
              className={styles.img}
              src={addImgIcon}
              alt="Добавить фото станции."
            />
            <p className="text text_type_medium-16 text_color_primary">
              {currentFileName
                ? currentFileName
                : "Загрузите фото в фотрмате JPG"}
            </p>
          </label>
        )}
        <input
          type="file"
          className={styles.file_input}
          name="image"
          id="image"
          onChange={onChangeInput}
        />
        <Input
          type="text"
          placeholder="Название станции"
          name="name"
          defaultValue={card.name}
          onChange={onChangeInput}
          error={errorName}
        />
        <Input
          type="text"
          placeholder="Линия метрополитена"
          name="line"
          defaultValue={card.line}
          onChange={onChangeInput}
          error={errorAge}
        />
        <Input
          type="text"
          placeholder="Ссылка на документацию"
          name="documentation"
          defaultValue={card.documentation}
          onChange={onChangeInput}
          error={errorAge}
        />
        <ButtonForm
          extraClass={styles.submit_btn}
          text="Сохранить"
          onClick={handleSubmit}
        />
        <ButtonSecondary
          extraClass={styles.return_btn}
          icon={returnIcon}
          onClick={handleReturn}
        />
      </div>
    </div>
  );
};
