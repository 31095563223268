import React, { useRef } from "react";
import { YMaps, Map, SearchControl, ObjectManager } from "@pbe/react-yandex-maps";

const defaultState = {
    center: [55.751574, 75.573856],
    zoom: 3
};

const style = {
    width: "100vw",
    height: "100vh"
};

const modules = ["layout.ImageWithContent", "GeoObjectCollection", "Placemark", 'geoObject.addon.balloon'];

const routes = [
    {
        routeId: "0116ЕАПКИР",
        coordinates: [55.613278, 98.568893]
    },
    {
        routeId: "0306ОТКК",
        coordinates: [59.988772, 78.242669]
    },
    {
        routeId: "0506ЕКТМОМНБ-1",
        coordinates: [54.988772, 73.242669]
    },
    {
        routeId: "3005ВК02ХБЧТИР",
        coordinates: [50.987747, 111.51267]
    },
    {
        routeId: "0506ПНКЕОТ",
        coordinates: [53.203076, 45.078721]
    },
    {
        routeId: "0506СПАРСД",
        coordinates: [62.554239, 42.803836]
    },
    {
        routeId: "0306СОСПНБ-1",
        coordinates: [54.664566, 55.862206]
    },
    {
        routeId: "0306МВОМ-2",
        coordinates: [55.12085, 38.818627]
    },
    {
        routeId: "0206ОТТК",
        coordinates: [55.331206, 78.44999]
    }
];

const iconContent = (routeId) =>
    `<div style='color:black;
     display: inline-block;
     font-size: 11px;
     font-weight: bold;
     line-height: 20px;
     padding: 0 5px;
     background: #FFF;
     margin-top: 1px;
     margin-left: 30px;
     opacity: 0.8;
     border-radius: 5px;
     -moz-border-radius: 5px;
     -webkit-border-radius: 5px;'>` +
    routeId +
    `</div>`;

const Mapex = () => {
    // Используем хук для получения ссылки на карту.
    const ymaps = useRef(null);

    function CustomSearchProvider(points) {
        this.points = points;
    }

    CustomSearchProvider.prototype.geocode = function (request, options) {
        let deferred = ymaps.current && ymaps.current.vow.defer();
        let geoObjects = ymaps.current && new ymaps.current.GeoObjectCollection();

        let offset = options.skip || 0;
        let limit = options.results || 20;

        let points = [];
        for (let i = 0, l = this.points.length; i < l; i++) {
            let point = this.points[i];

            if (point.routeId.toLowerCase().indexOf(request.toLowerCase()) !== -1) {
                points.push(point);
            }
        }

        // При формировании ответа можно учитывать offset и limit.
        points = points.splice(offset, limit);

        // Добавляем точки в результирующую коллекцию.
        

        deferred.resolve({
            // Геообъекты поисковой выдачи.
            geoObjects,
            // Метаинформация ответа.
            metaData: {
                geocoder: {
                    // Строка обработанного запроса.
                    request,
                    // Количество найденных результатов.
                    found: geoObjects.getLength(),
                    // Количество возвращенных результатов.
                    results: limit,
                    // Количество пропущенных результатов.
                    skip: offset
                }
            }
        });
        // Возвращаем объект-обещание.
        return deferred.promise();
    };

    const dataConvert = (routes) => {
        let features = [];
        routes &&
            routes.map((route, i) => {
                const lat = route.coordinates[0];
                const lon = route.coordinates[1];
                let tmpObj = {
                    type: "Feature",
                    id: route.routeId,
                    route: route,
                    geometry: {
                        type: "Point",
                        coordinates: [lat, lon],
                    },
                    
                    properties: {
                        
                        iconContent: iconContent(route.routeId),
                        balloonContentHeader: "Заголовок",
                    }
                };
                return features.push(tmpObj);
            });
        return features;
    };

    return (
        <YMaps>
            <Map
                defaultState={defaultState}
                style={style}
                modules={modules}
                onLoad={(api) => {
                    ymaps.current = api;
                }}
            >
                <SearchControl
                    options={{
                        float: "right",
                        maxWidth: 190,
                        noPlacemark: true,
                        provider: new CustomSearchProvider(routes),
                        resultsPerPage: 5
                    }}
                />
                <ObjectManager
                    options={{
                        clusterize: false,
                        gridSize: 150
                    }}
                    features={dataConvert(routes)}
                />
            </Map>
        </YMaps>
    );
};

export { Mapex }